let initialData = {};
export function setActiveData(value) {
    initialData = value;
}
export function getActiveData() {
    return initialData;
}
export function groupBy(objectArray, property) {
    return objectArray.reduce(function(acc, obj) {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
}

export function handleErrors(response) {
    if (!response.ok) throw Error(response.statusText);
    return response;
}

export function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== "";
}

export function randomHex(len) {
    let maxlen = 8,
        min = Math.pow(16, Math.min(len, maxlen) - 1);
    let max = Math.pow(16, Math.min(len, maxlen)) - 1,
        n = Math.floor(Math.random() * (max - min + 1)) + min,
        r = n.toString(16);
    while (r.length < len) {
        r = r + randomHex(len - maxlen);
    }
    return r;
}

export function randomCode(length) {
    let result = "";
    let characters = "0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function wait(ms) {
    return x => {
        return new Promise(resolve => setTimeout(() => resolve(x), ms));
    };
}
